<template>
  <!-- 我的订单 -->
  <div style="background:#eee">
    <van-sticky>
      <van-nav-bar title="转借记录" left-text="返回" left-arrow @click-left="goback" />
      <!-- 标签页 -->
      <van-tabs v-model="activeName" swipeable @click="tabChange">
        <van-tab v-if="authorization('/home-admin/device/device-borrow/all')" title="全部转借记录" :name="4" />
        <van-tab v-if="authorization('/home-admin/device/device-borrow/myLend')" title="我借出的" :name="1" />
        <van-tab v-if="authorization('/home-admin/device/device-borrow/myBorrow')" title="我借入的" :name="2" />
        <van-tab v-if="authorization('/home-admin/device/device-borrow/cancel')" title="取消记录" :name="3" />
      </van-tabs>
    </van-sticky>
    <!-- 列表 -->
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell-group v-for="(item, index) in SubHistoryList" :key="index" style="margin:1.2rem;text-align:left;" inset>
        <van-field label="借出时间" :value="dateFormat(item.createTime)" input-align="right" readonly label-width="5rem" />
        <!--        <van-field label="门店" :value="item.storeName" input-align="right" readonly label-width="8rem"/>-->
        <van-field label="充电桩型号" :value="item.categoryName" input-align="right" readonly label-width="8rem" />
        <van-field v-show="activeName === 1 || activeName === 3" label="借给谁" :value="item.inputName" input-align="right"
          readonly label-width="8rem" />
        <van-field v-show="activeName === 2 || activeName === 3" label="谁借的" :value="item.outName" input-align="right"
          readonly label-width="8rem" />
        <van-field label="借出数量" :value="item.count" input-align="right" readonly label-width="8rem" />
        <van-field label="转借状态" :value="statusFormat(item.status)" input-align="right" readonly label-width="8rem" />

        <van-row style="padding:1rem">
          <van-button v-show="item.status === 1 && activeName === 1" style="margin-left: 7rem" type="warning"
            size="small" @click="subCancel(index, item)">取消借出</van-button>
          <van-button v-show="item.status === 1 && activeName === 2" style="margin-left: 8rem" type="primary" size="small"
            @click="subConfirm(index, item)">确 认</van-button>

        </van-row>
      </van-cell-group>
    </van-list>
  </div>
</template>
<script>
import moment from "moment";
import { Dialog, Toast } from "vant";
import { authorization } from "../authorization/authorization";
export default {
  data() {
    return {
      activeName: 1,
      showTime: false,
      showRemarks: false,
      // showShortcut:false,
      searchVal: "",
      SubHistoryList: [],
      //当前选中的index
      orderListIndex: 0,
      loading: false,
      finished: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      params: {
        size: 5,
        page: 1,
        type: this.activeName
      },
      subscribeTimeParams: {
        subscribeTime: '',
        id: ''
      },
      addRemarkParams: {
        followRemark: "",
        id: ''
      },
      arouseParams: {
        id: "",
        status: "",
      },
      followRemarks: "",
      shortcutCol: ['长安7KW充电桩，无需带桩上门，充电桩在客户手里，白色的是我们安装，11张图', '7kw-充电桩安装施工不含电线，核销码，好评，11张图', '7kw-充电桩30米套包，核销码，好评，11张图', '比亚迪7kw充电桩，需要带桩上门，11张图', '比亚迪3.3kw充电桩，需要带桩上门，11张图'],
    };
  },
  mounted() {
    this.params.type = this.activeName
    this.querySubHistoryList()
  },
  methods: {
    authorization,
    getFollowRemarkList(followRemark) {
      if (followRemark === undefined || followRemark === null) {
        return []
      }
      return followRemark.split(";")
    },
    dateFormat(time) {
      if (time !== null && time !== undefined) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    statusFormat(status) {
      if (status === 1) {
        return "待确认"
      }
      if (status === 2) {
        return "已确认"
      }
      if (status === 3) {
        return "已取消"
      }
    },
    goback() {//返回上一页
      this.$router.push('/MyDevice');
    },
    goComplete(item) {//跳转到完成订单
      sessionStorage.setItem("orderId", item.id)
      this.$router.push({ path: '/CompleteOrder', query: { orderId: item.id } })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.params.page = this.params.page + 1
        this.querySubHistoryList();

      }, 1000);
    },
    querySubHistoryList() {
      this.axios({
        method: "get",
        url: "/v1/web/device-manage/get-history",
        params: this.params,
      }).then((res) => {
        if (this.params.page === 1) {
          this.SubHistoryList = []
        }
        for (let i = 0; i < res.data.data.list.length; i++) {
          this.SubHistoryList.push(res.data.data.list[i]);
        }
        if (res.data.data.list.length <= 0) {
          this.finished = true;
        }
        // 加载状态结束
        this.loading = false;
      });
    },
    tabChange(name) {
      this.params.type = name
      this.SubHistoryList = [];
      this.params.page = 1
      this.finished = false
      this.querySubHistoryList()
    },
    subscribeTimeClick(index, row) {
      this.subscribeTimeParams.id = row.id
      this.orderListIndex = index;
      this.showTime = true
    },
    subCancel(index, item) {
      Dialog.confirm({
        title: '取消转借',
        message: "请确认是否取消该设备转借",
      })
        .then(() => {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/operation-cancel/" + item.id
          }).then((res) => {
            if (res.data.code === 200) {
              this.params.page = 1
              this.querySubHistoryList()
              Toast.success("取消成功");
            } else {
              Toast.fail(res.data.message);
            }
          })
        })
    },
    subConfirm(index, item) {
      Dialog.confirm({
        title: '确认',
        message: "请确认是接收该转借设备",
      })
        .then(() => {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/operation-confirm/" + item.id
          }).then((res) => {
            if (res.data.code === 200) {
              this.params.page = 1
              this.querySubHistoryList()
              Toast.success("确认成功");
            } else {
              Toast.fail(res.data.message);
            }
          })
        })



    },
    addRemarkClick(index, row) {
      this.addRemarkParams.id = row.id
      this.followRemarks = ''
      this.addRemarkParams.followRemark = row.followRemark
      this.orderListIndex = index;
      this.showRemarks = true
    },
    confirmRemark() {
      if (this.addRemarkParams.followRemark === null || this.addRemarkParams.followRemark === undefined) {
        this.addRemarkParams.followRemark = ''
      }
      this.addRemarkParams.followRemark = this.addRemarkParams.followRemark + moment(new Date()).format("YYYY-MM-DD HH:mm") + ":;" + this.followRemarks + ";";
      this.axios({
        method: "post",
        url: "/v1/web/order-info/add-follow",
        data: this.addRemarkParams,
      }).then((res) => {
        console.log(this.addRemarkParams)
        if (res.data.code === 200) {
          this.showRemarks = false
          Toast.success("备注添加成功");
          this.orderList[this.orderListIndex].followRemark = this.addRemarkParams.followRemark
        } else {
          Toast.fail(res.data.message);
        }
      })
    },
    // onShortcut(val){//快捷语
    //   this.addRemarkParams.followRemark=this.addRemarkParams.followRemark+"  "+val
    //   this.showShortcut=false;
    // },
  },
};
</script>

<style>
.van-field__label {
  color: rgb(120, 120, 120) !important;
}

.van-field__control {
  color: rgb(180, 180, 180) !important;
}</style>
